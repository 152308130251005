import { zodResolver } from "@hookform/resolvers/zod";
import { defaultProfile, profileSchema } from "../../schemas";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { useForm } from "react-hook-form";
import { SectionDialog } from "../section-dialog";
import  URLInput  from "../url-input";
import { useDebounceValue } from "usehooks-ts";
import { useCallback } from "react";
import { Avatar, AvatarImage } from "@/common/components/ui/avatar";
const formSchema = profileSchema;

export const ProfilesDialog = () => {
  const form = useForm({
    defaultValues: defaultProfile,
    resolver: zodResolver(formSchema),
  });

  const [iconSrc, setIconSrc] = useDebounceValue("", 400);

  const handleIconChange = useCallback((event) => {
    if (event.target.value === "") {
      setIconSrc("");
    } else {
      setIconSrc(`https://cdn.simpleicons.org/${event.target.value}`);
    }
  }, [setIconSrc]);

  return (
    <SectionDialog id="profiles" form={form} defaultValues={defaultProfile}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <FormField
          name="network"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Network</FormLabel>
              <FormControl>
                
                <Input {...field} placeholder="LinkedIn" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="username"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input {...field} placeholder="john.doe" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="url"
          control={form.control}
          render={({ field }) => (
            <FormItem className="sm:col-span-2">
              <FormLabel>Website</FormLabel>
              <FormControl>
                <URLInput {...field} placeholder="https://linkedin.com/in/johndoe" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="icon"
          control={form.control}
          render={({ field }) => (
            <FormItem className="sm:col-span-2">
              <FormLabel htmlFor="iconSlug">Icon</FormLabel>
              <FormControl>
                <div className="flex items-center gap-x-2">
                  <Avatar className="size-8 bg-white">
                    {iconSrc && <AvatarImage className="p-1.5" src={iconSrc} />}
                  </Avatar>
                  <Input
                    {...field}
                    id="iconSlug"
                    placeholder="linkedin"
                    onChange={(event) => {
                      field.onChange(event);
                      handleIconChange(event);
                    }}
                  />
                </div>
              </FormControl>
              <FormMessage />
              <FormDescription className="ml-10">
                Powered by{" "}
                <a
                  href="https://simpleicons.org/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="font-medium"
                >
                  Simple Icons
                </a>
              </FormDescription>
            </FormItem>
          )}
        />
      </div>
    </SectionDialog>
  );
};

import * as React from "react";
import {
  AiFillWechat,
  AiOutlineCode,
  AiOutlineCodeSandbox,
  AiOutlineCompass,
  AiOutlineFileText,
  AiOutlineHome,
  AiOutlineSchedule,
  AiFillAudio,
} from "react-icons/ai";
import { FaHackerrank } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { FaTrophy, FaMedal } from "react-icons/fa";
import {
  HiOutlineSupport,
  HiOutlineUserGroup,
  HiOutlineVideoCamera,
} from "react-icons/hi";
import { IoBriefcaseOutline, IoDocumentTextOutline } from "react-icons/io5";
import { MdEvent, MdOutlineAssessment, MdOutlineGroups } from "react-icons/md";
import { ImBook } from "react-icons/im";
import { TiDocumentText } from "react-icons/ti";
import { NavMain } from "@/features/sidebar/components/nav-main";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  useSidebar,
} from "@/common/components/ui/sidebar";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { twMerge } from "tailwind-merge";
import { useAuthOperations } from "@/features/auth/hooks/useAuthOperations";
import { Link } from "react-router-dom";
import { LuSheet } from "react-icons/lu";

export default function DashboardSidebar({ ref, ...props }) {
  const {
    user: { profile: currentUser },
  } = useAuth();
  const { state, isMobile } = useSidebar();

  const { handleLogout } = useAuthOperations();

  const navigations = React.useMemo(() => {
    return [
      {
        pathMatch: "/home/dashboard",
        href: "/home/dashboard",
        icon: AiOutlineHome,
        label: "Dashboard",
        condition: true,
      },
      {
        pathMatch: "/home/tests",
        href: "/home/tests",
        icon: IoDocumentTextOutline,
        label: "Assessments",
        condition: currentUser?.university?.config?.test,
      },
      {
        pathMatch: "/home/contests",
        href: "/home/contests",
        icon: FaTrophy,
        label: "Contest Calendar",
        condition: currentUser?.university?.config?.hackerrank,
      },

      {
        pathMatch: "/home/test_challenges",
        href: "/home/test_challenges",
        icon: FaHackerrank,
        label: "Global Platform Assessments",
        condition: currentUser?.university?.config?.hackerrank,
      },
      {
        pathMatch: "/home/courses",
        href: "/home/courses",
        icon: ImBook,
        label: "Courses",
        condition: currentUser?.university?.config?.courses,
      },
      {
        pathMatch: "/home/sheets",
        href: "/home/sheets",
        icon: LuSheet,
        label: "DSA Sheets",
        // need to configure this condition
        condition: currentUser?.university?.config?.courses,
      },
      {
        pathMatch: "/home/events",
        href: "/home/events",
        icon: MdEvent,
        label: "Events",
        condition: currentUser?.university?.config?.events,
      },
      {
        pathMatch: "/home/challenges",
        href: "/home/challenges",
        icon: AiOutlineFileText,
        label: "Challenges",
        condition: currentUser?.university?.config?.challenges,
      },
      {
        pathMatch: "/home/stats",
        href: "/home/stats/dashboard",
        icon: AiOutlineCompass,
        label: "Explore",
        condition: currentUser?.university?.config?.explore,
      },
      {
        pathMatch: "/home/project_assignments",
        href: "/home/project_assignments",
        icon: MdOutlineAssessment,
        label: "Projects",
        condition: currentUser?.university?.config?.projects_assign,
      },
      {
        pathMatch: "/home/jobs",
        href: "/home/jobs",
        icon: IoBriefcaseOutline,
        label: "Jobs",
        condition: currentUser?.university?.config?.jobs,
      },
      {
        pathMatch: "/home/schedule",
        href: "/home/schedule",
        icon: AiOutlineSchedule,
        label: "Schedule",
        condition: currentUser?.university?.config?.schedule,
      },
      {
        pathMatch: "/home/live_session",
        href: "/home/live_session",
        icon: MdOutlineGroups,
        label: "Live Session",
        condition: currentUser?.university?.config?.live_session,
      },
      {
        pathMatch: "/home/communities",
        href: "/home/communities",
        icon: HiOutlineUserGroup,
        label: "Communities",
        condition: currentUser?.university?.config?.communities,
      },
      {
        pathMatch: "/home/sandbox",
        href: "/home/sandbox",
        icon: AiOutlineCodeSandbox,
        label: "Sandbox",
        condition: currentUser?.university?.config?.sandbox,
      },
      {
        pathMatch: "/home/expert-connect",
        href: "/home/expert-connect",
        icon: AiFillWechat,
        label: "Expert Connect",
        condition: currentUser?.university?.config?.expert_connect,
      },
      {
        pathMatch: "/home/support",
        href: "/home/support",
        icon: HiOutlineSupport,
        label: "Support",
        condition: currentUser?.university?.config?.support_page,
      },
      {
        pathMatch: "/home/ide",
        href: "/home/ide",
        icon: AiOutlineCode,
        label: "IDE",
        condition:
          currentUser?.university?.config?.ide &&
          currentUser?.university?.config?.compiler,
      },
      {
        pathMatch: "/home/AiInterview",
        href: "/home/AiInterview",
        icon: AiFillAudio,
        label: "AI Interview (new)",
        condition: true,
      },
      {
        pathMatch: "/home/resume_builder",
        href: "/home/resume_builder",
        icon: TiDocumentText,
        label: "Resume Builder",
        condition: true,
      },
      {
        pathMatch: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
        href: "/home/course/ed2cfc18-0314-4308-95fa-ab67f0069f0c/active",
        icon: HiOutlineVideoCamera,
        label: "Interviews",
        condition:
          currentUser?.university?.id ===
          "b760c92e-46ec-43a9-9c6d-3d6cf933564a",
      },
      {
        pathMatch: "/home/program_stats",
        href: "/home/program_stats",
        icon: FaMedal,
        label: "Program Stats",
        condition: true,
      },
    ];
  }, [currentUser]);

  if (currentUser?.university?.config?.hide_sidebar) {
    return null;
  }

  return (
    <Sidebar
      collapsible="icon"
      {...props}
      className="text-gray-700 bg-white border-r border-gray-200"
    >
      <SidebarHeader className="flex flex-row items-center justify-between p-6 border-b border-gray-200">
        {(state === "expanded" || isMobile) && (
          <Link to="/home/dashboard" className="cursor-pointer">
            <img
              src={currentUser?.university?.icon}
              className={twMerge("object-contain w-full h-12")}
              alt=""
            />
          </Link>
        )}
      </SidebarHeader>
      <SidebarContent className="py-4 bg-transparent">
        <NavMain items={navigations} ref={ref} />
      </SidebarContent>
      <SidebarFooter
        className={`flex gap-3 flex-row items-center border-t border-gray-200 ${
          (state === "expanded" || isMobile) && "p-6"
        }`}
      >
        <button
          onClick={handleLogout}
          className="flex items-center gap-3 p-2 transition-colors duration-200 rounded-lg cursor-pointer hover:bg-gray-100"
        >
          <GoSignOut className="w-4 h-4" />
          {(state === "expanded" || isMobile) && (
            <span className="text-base">Log out</span>
          )}
        </button>
      </SidebarFooter>
    </Sidebar>
  );
}

import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "@/lib/analytics/ga4";
import { AuthContext } from "@/features/auth/context/AuthContext";

const RouteTracker = () => {
  const location = useLocation();
  const { university } = useContext(AuthContext);

  useEffect(() => {
    // Track page view with university context
    trackPageView(location.pathname + location.search, university);
  }, [location, university]);

  return null;
};

export default RouteTracker;

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "../src/assets/styles/global.css";
import App from "./App.jsx";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
LogRocket.init("xysarp/ui");
Sentry.init({
  dsn: "https://b7131ed5613ef71b361bc40679a7b836@o4506513662803968.ingest.us.sentry.io/4508986029637632",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/meritcurve/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <App />
  </StrictMode>
);

import { gql } from "@apollo/client";

export const GET_DASHBOARD_LEARNING_PATHS = gql`
  query GetUserLearningPaths($user_id: uuid!) {
    learning_path(order_by: { created_at: desc }) {
      id
      title
      description
      learning_path_participants(where: { user_id: { _eq: $user_id } }) {
        id
      }
    }
  }
`;

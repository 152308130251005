import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
  query GetUserProfile($email: String!, $universityId: uuid!) {
    users(
      where: { email: { _eq: $email }, university_id: { _eq: $universityId } }
    ) {
      fullName
      id
      onboarding_complete
      is_trainer
      onboarding_step
      profile_picture
      university_id
      user_role {
        id
        role {
          id
          name
        }
      }
      university {
        id
        name
        description
        config
        support_email
        icon
        private_config
        isJobPortal
      }
    }
  }
`;

import { useState, useCallback } from "react";
import { useAuth } from "./useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getAuthErrorMessage } from "../utils/authErrors";

export const useAuthOperations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    login: authLogin,
    signup: authSignup,
    logout: authLogout,
    googleLogin: authGoogleLogin,
    githubLogin: authGithubLogin,
    microsoftLogin: authMicrosoftLogin,
    resetPassword: authResetPassword,
  } = useAuth();
  const navigate = useNavigate();

  const showErrorToast = useCallback((error) => {
    const message = getAuthErrorMessage(error);
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }, []);

  const showSuccessToast = useCallback((message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }, []);

  const handleAuthOperation = useCallback(async (operation) => {
    setError(null);
    try {
      const result = await operation();
      return result;
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      setError(errorMessage);
      throw err;
    }
  }, []);

  const handleLogin = useCallback(
    async (email, password) => {
      try {
        setIsLoading(true); // Ensure loading is set before auth operation
        const result = await handleAuthOperation(async () => {
          return await authLogin(email, password);
        });
        console.log("result: ", result);
        showSuccessToast("Successfully logged in!");
        navigate("/");
        return result;
      } catch (error) {
        console.error("Login error:", error);
        // Make sure error is shown to user
        showErrorToast(error);
      } finally {
        setIsLoading(false); // Ensure loading is always cleared
      }
    },
    [authLogin, handleAuthOperation, navigate, showSuccessToast, showErrorToast]
  );

  const handleSignup = useCallback(
    async (email, password) => {
      try {
        setIsLoading(true);
        const result = await handleAuthOperation(async () => {
          return await authSignup(email, password);
        });
        showSuccessToast("Account created successfully!");
        navigate("/");
        return result;
      } catch (error) {
        console.error("Signup error:", error);
      } finally {
        setIsLoading(false); // Ensure loading is always cleared
      }
    },
    [authSignup, handleAuthOperation, navigate, showSuccessToast]
  );

  const handleLogout = useCallback(async () => {
    try {
      await handleAuthOperation(async () => {
        await authLogout();
      });
      showSuccessToast("Successfully logged out!");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [authLogout, handleAuthOperation, navigate, showSuccessToast]);

  const handleSocialLogin = useCallback(
    async (provider) => {
      try {
        setIsLoading(true);
        let authProvider;
        let socialLogin;
        switch (provider) {
          case "google":
            socialLogin = authGoogleLogin;
            break;
          case "github":
            socialLogin = authGithubLogin;
            break;
          case "microsoft":
            socialLogin = authMicrosoftLogin;
            break;
          default:
            throw new Error("Invalid provider");
        }

        const result = await handleAuthOperation(async () => {
          return await socialLogin();
        });
        showSuccessToast(`Successfully logged in!`);
        navigate("/");
        return result;
      } catch (error) {
        showErrorToast(error);
        console.error("Social login error:", error);
      } finally {
        setIsLoading(false); // Ensure loading is always cleared
      }
    },
    [
      handleAuthOperation,
      showSuccessToast,
      navigate,
      authGoogleLogin,
      authGithubLogin,
      authMicrosoftLogin,
      showErrorToast,
    ]
  );

  const handleResetPassword = useCallback(
    async (email) => {
      try {
        setIsLoading(true);
        await handleAuthOperation(async () => {
          await authResetPassword(email);
          showSuccessToast(
            "We've sent you instructions to reset your password."
          );
        });
      } catch (error) {
        showErrorToast(error);
      } finally {
        setIsLoading(false); // Ensure loading is always cleared
      }
    },
    [authResetPassword, handleAuthOperation, showSuccessToast, showErrorToast]
  );

  return {
    isLoading,
    error,
    handleSignup,
    handleLogin,
    handleLogout,
    handleSocialLogin,
    handleResetPassword,
  };
};

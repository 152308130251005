import ReactGA from "react-ga4";

// Your Google Analytics measurement ID from GA4
const MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;

// Initialize Google Analytics with university information
export const initGA = (universityInfo = null) => {
  if (MEASUREMENT_ID && typeof window !== "undefined") {
    ReactGA.initialize(MEASUREMENT_ID, {
      debug_mode: true,
      gaOptions: {
        debug_mode: import.meta.env.DEV,
      },
    });

    // Set global university/domain information if available
    if (universityInfo) {
      setUniversityData(universityInfo);
    }

    console.log("Google Analytics initialized successfully");
  } else if (!MEASUREMENT_ID) {
    console.warn("Google Analytics Measurement ID not found");
  }
};

// Set university/domain data as custom dimensions
export const setUniversityData = (university) => {
  if (typeof window !== "undefined" && MEASUREMENT_ID && university) {
    ReactGA.set({
      university_id: university.id,
      university_name: university.name,
      university_domain: university.domain,
      university_slug: university.slug,
      tenant_id: university.tenantId,
    });
  }
};

// Update the trackPageView function
export const trackPageView = (path, universityInfo = null) => {
  if (typeof window !== "undefined" && MEASUREMENT_ID) {
    // For page views, include university info as event parameters
    const eventParams = {};

    if (universityInfo) {
      eventParams.university_id = universityInfo.id;
      eventParams.university_name = universityInfo.name;
      eventParams.university_domain = universityInfo.domain;
    }

    ReactGA.send({
      hitType: "pageview",
      page: path,
      ...eventParams,
    });

    // Also track as a custom event with university params
    ReactGA.event({
      category: "Page View",
      action: path,
      label: universityInfo?.name || "Unknown University",
      university_id: universityInfo?.id,
      university_name: universityInfo?.name,
      university_domain: universityInfo?.domain,
    });
  }
};

// Modify event tracking
export const trackEvent = (
  category,
  action,
  label,
  value,
  universityInfo = null
) => {
  if (typeof window !== "undefined" && MEASUREMENT_ID) {
    const eventParams = {
      event_category: category,
      event_label: label,
    };

    if (value !== undefined) {
      eventParams.value = value;
    }

    // Add university info directly to event parameters
    if (universityInfo) {
      eventParams.university_id = universityInfo.id;
      eventParams.university_name = universityInfo.name;
      eventParams.university_domain =
        universityInfo.domain || window.location.hostname;
    }

    ReactGA.event({
      action,
      ...eventParams,
    });
  }
};

// Set user ID when known
export const setUserID = (userId) => {
  if (typeof window !== "undefined" && MEASUREMENT_ID && userId) {
    ReactGA.set({ userId });
  }
};

import { useState, useEffect, useRef } from "react";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_LAST_ONLINE = gql`
  mutation UpdateLastOnline($id: uuid!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { last_online: "now()" }
    ) {
      id
      last_online
    }
  }
`;

export function TimeTrackingService() {
  const { user, getLatestToken } = useAuth();
  const [serverTime, setServerTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(0);
  const [showTimeWarning, setShowTimeWarning] = useState(false);
  const intervalRef = useRef(null);

  const [updateLastOnline] = useMutation(UPDATE_LAST_ONLINE);

  useEffect(() => {
    console.log("TimeTrackingService effect running", { user });

    // Clear any existing interval first
    if (intervalRef.current) {
      console.log("Clearing existing interval");
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // Only start tracking if user is fully authenticated
    if (!user?.token || !user?.profile?.id) {
      console.log("User not authenticated, skipping time tracking setup");
      return;
    }

    console.log("Setting up time tracking for user", user.profile.id);

    // Function to check time sync and update last online
    const updateUserActivity = async () => {
      // Verify user is still logged in before making the request
      if (!user?.token || !user?.profile?.id) {
        console.log("User no longer authenticated, stopping interval");
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        return;
      }

      try {
        const clientTimeBeforeRequest = new Date();

        const response = await updateLastOnline({
          variables: { id: user.profile.id },
        });

        const clientTimeAfterRequest = new Date();

        if (response.data?.update_users_by_pk?.last_online) {
          // Get server timestamp from response
          const serverTimeStr = response.data.update_users_by_pk.last_online;

          // Parse the server time - handle PostgreSQL timestamp format
          // The server returns a string like "2025-03-16T14:30:00.000000"
          const serverTimeObj = new Date(serverTimeStr);

          setServerTime(serverTimeObj);

          // Account for network latency by using average of before/after request times
          const avgClientTime = new Date(
            (clientTimeBeforeRequest.getTime() +
              clientTimeAfterRequest.getTime()) /
              2
          );

          // Calculate time difference in seconds (absolute value)
          const diffSeconds = Math.abs(
            (serverTimeObj.getTime() - avgClientTime.getTime()) / 1000
          );

          setTimeDifference(diffSeconds);

          // Show warning if difference is more than 3 minutes (180 seconds)
          if (diffSeconds > 180) {
            setShowTimeWarning(true);
          } else {
            setShowTimeWarning(false);
          }
        }
      } catch (error) {
        console.error("Failed to update last online status:", error);
      }
    };

    // Initial update
    updateUserActivity();

    // Set interval for periodic updates
    intervalRef.current = setInterval(updateUserActivity, 30000);

    // Force a check when window regains focus (user may have changed time while window was not active)
    window.addEventListener("focus", updateUserActivity);

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      window.removeEventListener("focus", updateUserActivity);
    };
  }, [user?.profile?.id, updateLastOnline, user]);

  if (showTimeWarning) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
        <div className="w-full max-w-md p-8 mx-4 bg-white rounded-lg shadow-xl">
          <h2 className="mb-4 text-2xl font-bold text-red-600">
            System Time Mismatch
          </h2>

          <div className="mb-6">
            <p className="mb-4 text-gray-700">
              Your system time is significantly different from the server time.
              This may cause issues with the application.
            </p>

            <div className="p-4 mb-4 bg-gray-100 rounded-md">
              <div className="mb-2">
                <span className="font-medium">Server time:</span>
                <span className="ml-2 text-green-600">
                  {serverTime?.toLocaleString()}
                </span>
              </div>
              <div className="mb-2">
                <span className="font-medium">Your system time:</span>
                <span className="ml-2 text-red-600">
                  {new Date().toLocaleString()}
                </span>
              </div>
              <div>
                <span className="font-medium">Difference:</span>
                <span className="ml-2 text-orange-500">
                  {Math.floor(timeDifference / 60)} minutes{" "}
                  {Math.floor(timeDifference % 60)} seconds
                </span>
              </div>
            </div>

            <h3 className="mb-2 font-bold">
              Please update your system time to continue:
            </h3>
            <ol className="pl-5 space-y-2 list-decimal">
              <li>Open your system settings</li>
              <li>Navigate to Date & Time settings</li>
              <li>
                Enable automatic time synchronization or set the correct time
                manually
              </li>
              <li>Refresh this page after updating your time</li>
            </ol>
          </div>

          <div className="flex justify-center">
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 text-white transition-colors bg-blue-600 rounded hover:bg-blue-700"
            >
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Render nothing when everything is fine
  return null;
}

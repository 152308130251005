import React from "react";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuGroup,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from "@/common/components/ui/dropdown-menu";
import { useAuth } from "@/features/auth/hooks/useAuth";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "@/common/components/ui/avatar";
import { useNavigate } from "react-router-dom";
import { Badge } from "@/common/components/ui/badge";
import { LifeBuoy, LogOut, Settings, User, Users } from "lucide-react";
import { useAuthOperations } from "@/features/auth/hooks/useAuthOperations";

const UserProfileDropDown = () => {
  const { user } = useAuth();
  const { handleLogout } = useAuthOperations();
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar className="w-10 h-10 cursor-pointer">
          <AvatarImage src={user?.profile?.profile_picture} alt="Profile" />
          <AvatarFallback>
            {user?.profile?.fullName
              .split(" ")
              .map((name) => name?.at(0)?.toUpperCase())
              .join("")
              .slice(0, 2)}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="flex items-center justify-start gap-2 p-2">
          <div className="flex items-center gap-2">
            <Avatar className="w-10 h-10">
              <AvatarImage src={user?.profile?.profile_picture} alt="Profile" />
              <AvatarFallback>
                {user?.profile?.fullName
                  .split(" ")
                  .map((name) => name[0]?.toUpperCase())
                  .join("")
                  .slice(0, 2)}
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col space-y-0.5">
              <p className="text-sm font-medium">{user?.profile?.fullName}</p>
              <p className="text-xs text-muted-foreground">{user?.email}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-1">
          <Badge variant="outline" className="gap-1">
            <div className="bg-blue-500 rounded size-3" />
            <span>0</span>
          </Badge>
          <Badge variant="outline" className="gap-1">
            <div className="bg-red-500 rounded size-3" />
            <span>0</span>
          </Badge>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => navigate("/home/profile")}>
            <User className="w-4 h-4 mr-2" />
            <span>View Profile</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate("/home/edit")}>
            <Settings className="w-4 h-4 mr-2" />
            <span>Edit Profile</span>
          </DropdownMenuItem>
          {user.profile.university.config.communities && (
            <DropdownMenuItem onClick={() => navigate("/home/communities")}>
              <Users className="w-4 h-4 mr-2" />
              <span>Communities</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        {user.profile.university.support_email && (
          <DropdownMenuItem>
            <LifeBuoy className="w-4 h-4 mr-2" />
            <span>Help ({user.profile.university.support_email})</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onClick={handleLogout}
          className="text-red-600 hover:text-white hover:bg-red-600"
        >
          <LogOut className="w-4 h-4 mr-2" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserProfileDropDown;

import { ProjectsDialog } from "./dialogs/projects-dialog";
import { CertificationsDialog } from "./dialogs/certifications";
import { AwardsDialog } from "./dialogs/awards";
import {CustomSectionDialog} from "./dialogs/custom-section";
import {ExperienceDialog} from "./dialogs/experience";
import { EducationDialog } from "./dialogs/education";
import {InterestsDialog} from "./dialogs/interests";
import {LanguagesDialog} from "./dialogs/languages";
import {ProfilesDialog} from "./dialogs/profiles";
import {SkillsDialog} from "./dialogs/skills";
import {ReferencesDialog} from "./dialogs/references";
import {PublicationsDialog} from "./dialogs/publications";
import {VolunteerDialog} from "./dialogs/volunteer";
import { useResumeStore } from "../contexts/resumeContext";

const DialogProvider = ({ children }) => {
  const isResumeLoaded = useResumeStore((state) => Object.keys(state.resume).length > 0);

  return (
    <>
      {children}

      <div id="dialog-root">
        {isResumeLoaded && (
          <>
            <ProjectsDialog />
            <CertificationsDialog />
            <AwardsDialog />
            <CustomSectionDialog />
            <ExperienceDialog />
            <EducationDialog />
            <InterestsDialog />
            <LanguagesDialog />
            <ProfilesDialog />
            <SkillsDialog />
            <ReferencesDialog />
            <PublicationsDialog />
            <VolunteerDialog />
            
          </>
        )}
      </div>
    </>
  );
};

export default DialogProvider;

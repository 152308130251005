import DashboardSidebar from "@/features/sidebar/components/app-sidebar";
import { Separator } from "@/common/components/ui/separator";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@/common/components/ui/sidebar";

import { Link } from "react-router-dom";
import { useAuth } from "../../auth/hooks/useAuth";
import UserProfileDropDown from "@/common/components/shared/UserProfileDropDown";

const Sidebar = ({ children }) => {
  const {
    user: { profile: currentUser },
  } = useAuth();
  return (
    <SidebarProvider>
      <DashboardSidebar />
      <SidebarInset>
        <header className="sticky top-0 z-50 flex flex-row justify-between w-full p-3 bg-white">
          <div className="flex items-center gap-2">
            <SidebarTrigger />
            <Separator orientation="vertical" className="h-4" />
            <Link to="/home" className="flex md:w-auto">
              <span className="mr-auto text-lg">
                {currentUser?.university?.name}
              </span>
            </Link>
          </div>
          {currentUser && (
            <div>
              <UserProfileDropDown />
            </div>
          )}
        </header>
        <div className="flex flex-col flex-grow bg-[#f8f9fc]">{children}</div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default Sidebar;


import { Link } from "react-router-dom";
import { useImperativeHandle, useState } from "react";
import { SidebarGroup, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@/common/components/ui/sidebar";

export function NavMain({ items, ref }) {
  const [open, setOpen] = useState(true);

  const { pathname: path } = location;
  useImperativeHandle(ref, () => ({
    setOpen,
  }));
  return (
    <SidebarGroup>
      <SidebarMenu className="space-y-1">
        {items.map(
          ({ pathMatch, icon: Icon, label, condition, href }, index) => {
            const isActive = path.startsWith(pathMatch);

            return (
              condition && (
                <SidebarMenuItem key={index}>
                  <Link to={href} className="w-full">
                    <SidebarMenuButton
                      className={`w-full text-[16px] py-4 px-6 rounded-lg transition-all duration-200 flex items-center gap-4 cursor-pointer font-normal hover:bg-sidebar-accent ${
                        isActive
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50"
                      }`}
                      tooltip={label}
                    >
                      <Icon className="w-[24px] h-[24px] flex-shrink-0" />
                      <span className="font-medium leading-none">{label}</span>
                    </SidebarMenuButton>
                  </Link>
                </SidebarMenuItem>
              )
            );
          }
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}

import { createContext, useEffect, useState, useCallback, useRef } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "@/config/firebase";
import { getAuthErrorMessage } from "../utils/authErrors";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"; // Add these imports
import { GET_USER_PROFILE } from "../queries/userQueries";
import { setAuthGetter } from "@/lib/apollo/apolloClient";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { setUserID } from "@/lib/analytics/ga4";
const VITE_FUNCTION_BASE_URL = import.meta.env.VITE_FUNCTION_BASE_URL;
const VITE_HASURA_ENDPOINT = import.meta.env.VITE_HASURA_GRAPHQL_URL; // Add this import
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
if (import.meta.env.MODE === "development") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

// Create a dedicated Apollo client for AuthContext
const createAuthApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: VITE_HASURA_ENDPOINT,
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
};

// Create context with default shape
export const AuthContext = createContext({
  user: null,
  loading: true,
  isRefreshing: false,
  setCurrentUser: async () => {},
  login: async () => {},
  signup: async () => {},
  logout: async () => {},
  googleLogin: async () => {},
  githubLogin: async () => {},
  microsoftLogin: async () => {},
  resetPassword: async () => {},
  refreshToken: async () => {},
  refreshUserProfile: async () => {},
});

const TOKEN_REFRESH_MARGIN = 5 * 60 * 1000; // 5 minutes in milliseconds
const TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds
const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

export const AuthProvider = ({ children, university }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshTimeoutRef = useRef(null);
  const refreshIntervalRef = useRef(null);
  const retryAttemptsRef = useRef(0);
  const authApolloClient = useRef(createAuthApolloClient()); // Create dedicated Apollo client

  const clearRefreshTimers = useCallback(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = null;
    }
    if (refreshIntervalRef.current) {
      clearInterval(refreshIntervalRef.current);
      refreshIntervalRef.current = null;
    }
  }, []);

  const handleAuthError = useCallback((error, customMessage = "") => {
    const errorMessage = getAuthErrorMessage(error) || customMessage;

    // toast.error(errorMessage, {
    //   position: "top-left",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
    console.error("Auth error:", error);

    Sentry.captureException(error, {
      tags: {
        component: "AuthContext",
        action: customMessage || "auth_error",
      },
      extra: {
        customMessage,
      },
    });

    console.error(customMessage || "Authentication error:", error);
    return error;
  }, []);

  const fetchUserProfile = useCallback(
    async (email, token) => {
      try {
        const { data, error } = await authApolloClient.current.query({
          query: GET_USER_PROFILE,
          variables: {
            email,
            universityId: university?.id,
          },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        });

        if (error) {
          throw error;
        }

        if (!data?.users?.[0]) {
          throw new Error("User profile not found");
        }

        return data.users[0];
      } catch (error) {
        handleAuthError(error, "Failed to fetch user profile");
        throw error;
      }
    },
    [handleAuthError, university?.id]
  );

  const refreshToken = useCallback(
    async (force = false) => {
      if (isRefreshing && !force) return null;

      try {
        setIsRefreshing(true);
        const currentUser = auth.currentUser;
        if (!currentUser)
          throw new Error("Session expired. Please sign in again to continue");

        const token = await currentUser.getIdToken(force);
        const tokenResult = await currentUser.getIdTokenResult();
        const expirationTime = new Date(tokenResult.expirationTime).getTime();

        setUser((prev) => ({
          ...prev,
          token,
          tokenExpiration: expirationTime,
        }));

        retryAttemptsRef.current = 0;

        const timeUntilRefresh =
          expirationTime - Date.now() - TOKEN_REFRESH_MARGIN;
        if (timeUntilRefresh > 0) {
          refreshTimeoutRef.current = setTimeout(
            () => refreshToken(true),
            timeUntilRefresh
          );
        } else {
          await refreshToken(true);
        }

        return token;
      } catch (error) {
        if (retryAttemptsRef.current < MAX_RETRY_ATTEMPTS) {
          retryAttemptsRef.current++;
          console.warn(
            `Token refresh failed, retrying (${retryAttemptsRef.current}/${MAX_RETRY_ATTEMPTS})...`
          );
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          return refreshToken(force);
        }
        handleAuthError(error, "Session expired. Please sign in again.");
        await logout();
        throw error;
      } finally {
        setIsRefreshing(false);
      }
    },
    [isRefreshing, handleAuthError]
  );

  const updateUserData = useCallback(
    async (firebaseUser) => {
      try {
        let token = await firebaseUser.getIdToken();
        let tokenResult = await firebaseUser.getIdTokenResult();

        const hasuraClaim = tokenResult.claims["https://hasura.io/jwt/claims"];

        if (!hasuraClaim) {
          const urlParams = new URLSearchParams(window.location.search);
          const slug = urlParams.get("slug");

          const tokenRes = await fetch(
            `${VITE_FUNCTION_BASE_URL}/meritcurveClient/user/setCustomToken?token=${token}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                slug: slug || "demo",
              }),
            }
          );

          if (!tokenRes.ok) {
            throw new Error(
              (await tokenRes.text()) ||
                `Failed to set custom claims: ${tokenRes.status}`
            );
          }

          await firebaseUser.getIdToken(true);
          tokenResult = await firebaseUser.getIdTokenResult();
          token = await firebaseUser.getIdToken();
        }

        const userProfile = await fetchUserProfile(firebaseUser.email, token);

        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          token,
          tokenExpiration: new Date(tokenResult.expirationTime).getTime(),
          profile: userProfile,
          hasuraClaims: tokenResult.claims["https://hasura.io/jwt/claims"],
        });

        refreshIntervalRef.current = setInterval(
          () => refreshToken(true),
          TOKEN_REFRESH_INTERVAL
        );

        const timeUntilExpiration =
          new Date(tokenResult.expirationTime).getTime() - Date.now();
        if (timeUntilExpiration <= TOKEN_REFRESH_MARGIN) {
          await refreshToken(true);
        } else {
          refreshTimeoutRef.current = setTimeout(
            () => refreshToken(true),
            timeUntilExpiration - TOKEN_REFRESH_MARGIN
          );
        }
      } catch (error) {
        setLoading(false);
        handleAuthError(error, "Error updating user data");
        await logout();
        throw error;
      }
    },
    [refreshToken, handleAuthError, fetchUserProfile]
  );

  const login = useCallback(
    async (email, password) => {
      try {
        if (!university?.tenantId) {
          throw new Error("University configuration not loaded");
        }
        auth.tenantId = university.tenantId;
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        await updateUserData(userCredential.user);
        toast({
          title: "Success",
          description: "Logged in successfully!",
          variant: "success",
        });
        return userCredential;
      } catch (error) {
        handleAuthError(error, "Login error");
        throw error;
      }
    },
    [updateUserData, handleAuthError, university?.tenantId]
  );

  const signup = useCallback(
    async (email, password) => {
      try {
        if (!university?.tenantId) {
          throw new Error("University configuration not loaded");
        }
        auth.tenantId = university.tenantId;
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await updateUserData(userCredential.user);
        toast({
          title: "Success",
          description: "Account created successfully!",
          variant: "success",
        });
        return userCredential;
      } catch (error) {
        handleAuthError(error, "Signup error");
        throw error;
      }
    },
    [updateUserData, handleAuthError, university?.tenantId]
  );

  const logout = useCallback(async () => {
    try {
      // Clear timers first
      clearRefreshTimers();

      // Sign out from Firebase
      await signOut(auth);

      // Important: Explicitly set user to null
      setUser(null);
      await authApolloClient.current.clearStore();
      toast({
        title: "Success",
        description: "Logged out successfully",
        variant: "success",
      });
    } catch (error) {
      handleAuthError(error, "Error during logout");
      return false;
    }
  }, [clearRefreshTimers, handleAuthError]);

  const handleSocialLogin = useCallback(
    async (provider) => {
      try {
        if (!university?.tenantId) {
          throw new Error("University configuration not loaded");
        }
        auth.tenantId = university.tenantId;
        provider.setCustomParameters({
          prompt: "select_account",
          tenant: university.tenantId,
        });
        const result = await signInWithPopup(auth, provider);
        await updateUserData(result.user);
        toast({
          title: "Success",
          description: "Logged in successfully!",
          variant: "success",
        });
        return result;
      } catch (error) {
        console.log("error: ", error);
        handleAuthError(error, "Social login error");
        throw error;
      }
    },
    [updateUserData, handleAuthError, university?.tenantId]
  );

  const googleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return handleSocialLogin(provider);
  }, [handleSocialLogin]);

  const githubLogin = useCallback(async () => {
    return handleSocialLogin(new GithubAuthProvider());
  }, [handleSocialLogin]);

  const microsoftLogin = useCallback(async () => {
    return handleSocialLogin(new OAuthProvider("microsoft.com"));
  }, [handleSocialLogin]);

  const resetPassword = useCallback(
    async (email) => {
      try {
        auth.tenantId = university?.tenantId;
        await sendPasswordResetEmail(auth, email);
        toast({
          title: "Success",
          description: "Password reset email sent successfully!",
          variant: "success",
        });
      } catch (error) {
        handleAuthError(error, "Reset password error");
        throw error;
      }
    },
    [handleAuthError, university?.tenantId] // Removed toast dependency
  );

  const refreshUserProfile = useCallback(
    async () => {
      if (!user?.email || !user?.token) return;

      try {
        const userProfile = await fetchUserProfile(user.email, user.token);
        setUser((currentUser) => ({
          ...currentUser,
          profile: userProfile,
        }));
        toast({
          title: "Success",
          description: "Profile refreshed successfully",
          variant: "success",
        });
      } catch (error) {
        handleAuthError(error, "Error refreshing user profile");
      }
    },
    [user?.email, user?.token, fetchUserProfile, handleAuthError] // Removed toast dependency
  );

  const setCurrentUser = useCallback((userData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...userData,
    }));
  }, []);

  useEffect(() => {
    let unsubscribe;
    try {
      unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        try {
          if (firebaseUser) {
            await updateUserData(firebaseUser);
          } else {
            clearRefreshTimers();
            setUser(null);
          }
        } catch (error) {
          handleAuthError(error, "Auth state change error");
          setUser(null);
        } finally {
          setLoading(false);
        }
      });
    } catch (error) {
      handleAuthError(error, "Error setting up auth listener");
      setLoading(false);
    }

    return () => {
      if (unsubscribe) unsubscribe();
      clearRefreshTimers();
    };
  }, [clearRefreshTimers, updateUserData, handleAuthError]);

  useEffect(() => {
    setAuthGetter(() => ({
      user,
      getLatestToken: async () => {
        if (!user) return null;

        const now = Date.now();
        const tokenExpiration = user.tokenExpiration;
        const timeUntilExpiry = tokenExpiration - now;

        if (timeUntilExpiry < 5 * 60 * 1000) {
          return await refreshToken(true);
        }

        return user.token;
      },
    }));
  }, [user, refreshToken]);

  // Add this effect to update Sentry user context
  useEffect(() => {
    if (user?.profile) {
      // Set user context for error tracking
      Sentry.setUser({
        id: user.profile.id,
        email: user.email,
        username: user.profile.fullName,
        role: user.profile.user_role?.role?.name || "unknown",
        university: user.profile.university?.name,
      });

      // Add Google Analytics user tracking
      setUserID(user?.profile?.id);

      // Add user attributes as context data
      Sentry.setContext("user_details", {
        onboarding_complete: user.profile.onboarding_complete,
        is_trainer: user.profile.is_trainer,
        university_id: user.profile.university_id,
      });

      LogRocket.identify(user.email, {
        name: user.profile.fullName,
        email: user.email,
      });

      // Add breadcrumb for sign-in events
      Sentry.addBreadcrumb({
        category: "auth",
        message: "User authenticated",
        level: "info",
      });
    } else {
      // Clear user data when logged out
      Sentry.setUser(null);
      Sentry.setContext("user_details", null);

      // Clear Google Analytics user ID by setting to null
      setUserID(null);

      if (loading === false) {
        Sentry.addBreadcrumb({
          category: "auth",
          message: "User logged out",
          level: "info",
        });
      }
    }
  }, [user, loading]);

  // Combine loading states
  const isInitializing = loading;

  const value = {
    user,
    loading: isInitializing,
    isRefreshing,
    university,
    setCurrentUser,
    login,
    signup,
    logout,
    googleLogin,
    githubLogin,
    microsoftLogin,
    resetPassword,
    refreshToken,
    refreshUserProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isInitializing && children}
    </AuthContext.Provider>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { defaultLanguage, languageSchema } from "../../schemas";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/common/components/ui/form";
import { Input } from "@/common/components/ui/input";
import { useForm } from "react-hook-form";
import { SectionDialog } from "../section-dialog";
import { Slider } from "@/common/components/ui/slider";

const formSchema = languageSchema;

export const LanguagesDialog = () => {
  const form = useForm({
    defaultValues: defaultLanguage,
    resolver: zodResolver(formSchema),
  });

  return (
    <SectionDialog id="languages" form={form} defaultValues={defaultLanguage}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <FormField
          name="name"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="description"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="level"
          control={form.control}
          render={({ field }) => (
            <FormItem className="sm:col-span-2">
              <FormLabel>Level</FormLabel>
              <FormControl className="py-2">
                <div className="flex items-center gap-x-4">
                  <Slider
                    {...field}
                    min={0}
                    max={5}
                    value={[Number(field.value)]} // Ensure the value is a number
                    onValueChange={(value) => {
                      const newValue = Number(value[0]);
                      field.onChange(newValue); // Pass a number to react-hook-form
                      field.onBlur(); // Trigger validation on blur
                    }}
                  />


                  {Number(field.value) === 0 ? (
                    <span className="text-base font-bold">Hidden</span>
                  ) : (
                    <span className="text-base font-bold">{Number(field.value)}</span>
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </SectionDialog>
  );
};

export const getAuthErrorMessage = (error) => {
  console.log("Processing error in getAuthErrorMessage:", error);

  // Safety check - if error is undefined or null
  if (!error) {
    return "An unknown error occurred";
  }

  // If error is a string, return it directly
  if (typeof error === "string") {
    return error;
  }

  // Check for Firebase error code
  if (error.code) {
    console.log("Found error code:", error.code);

    switch (error.code) {
      case "auth/invalid-email":
        return "Invalid email address format. Please enter a valid email (e.g., name@example.com).";
      case "auth/user-disabled":
        return "This account has been disabled by an administrator. Please contact support for assistance.";
      case "auth/user-not-found":
        return "No account found with this email address. Please check your spelling or create a new account.";
      case "auth/wrong-password":
        return "Incorrect password. Please try again or use the 'Forgot Password' option to reset it.";
      case "auth/email-already-in-use":
        return "An account already exists with this email address. Please sign in instead or use a different email.";
      case "auth/weak-password":
        return "Password is too weak. It should be at least 6 characters and include a mix of letters, numbers, and symbols.";
      case "auth/network-request-failed":
        return "Network connection error. Please check your internet connection and try again. If the problem persists, try again later.";
      case "auth/popup-closed-by-user":
        return "The authentication popup was closed before the process completed. Please try again and keep the window open until the process finishes.";
      case "auth/operation-not-allowed":
        return "This sign-in method is not enabled for this project. Please contact support or try another sign-in method.";
      case "auth/quota-exceeded":
        return "Quota exceeded: Too many authentication requests have been made from your account in a short period. Please try again after some time.";
      case "auth/requires-recent-login":
        return "This operation requires recent authentication. Please sign out and sign in again to complete this sensitive action.";
      case "auth/too-many-requests":
        return "Access temporarily blocked due to too many failed attempts. Please try again later or reset your password.";
      case "auth/token-expired":
        return "Your authentication session has expired. Please sign in again to continue using the application.";
      case "auth/invalid-credential":
        return "The provided credential is invalid or has expired. Please try again with valid credentials.";

      // Additional Firebase Auth error codes
      case "auth/account-exists-with-different-credential":
        return "An account already exists with the same email but different sign-in credentials. Try signing in using a provider associated with this email address.";
      case "auth/expired-action-code":
        return "The action code has expired. Please request a new verification email or password reset link.";
      case "auth/invalid-action-code":
        return "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";
      case "auth/invalid-verification-code":
        return "The verification code you entered is invalid. Please check and try again.";
      case "auth/invalid-phone-number":
        return "The phone number is invalid. Please enter a valid phone number in the format: +1 (555) 555-5555.";
      case "auth/missing-verification-code":
        return "The verification code is missing. Please enter the verification code sent to your device.";
      case "auth/user-mismatch":
        return "The provided credentials do not correspond to the previously signed in user. Please sign in with the correct account.";
      case "auth/invalid-persistence-type":
        return "The specified persistence type is invalid. It can only be 'local', 'session' or 'none'.";
      case "auth/web-storage-unsupported":
        return "Web storage is not supported or is disabled. Please enable cookies and local storage in your browser settings.";
      case "auth/timeout":
        return "The operation has timed out. Please try again later.";
      case "auth/user-cancelled":
        return "The user cancelled the sign-in process. Please try again when ready.";
      case "auth/unverified-email":
        return "Your email address has not been verified. Please check your inbox for a verification email and follow the instructions.";
      case "auth/rejected-credential":
        return "The request contains malformed or mismatched credentials. Please try again with valid credentials.";
      case "auth/invalid-tenant-id":
        return "The tenant ID provided is invalid. Please contact support for assistance.";
      case "auth/multi-factor-auth-required":
        return "Multi-factor authentication is required to continue. Please complete the second verification step.";
      default:
        return (
          error.message ||
          "An unexpected authentication error occurred. Please try again later."
        );
    }
  }

  // If we get here, we have an error object but no code
  return (
    error.message ||
    "An unexpected error occurred. Please try again or contact support."
  );
};

import { ApolloProvider as BaseApolloProvider } from "@apollo/client";
import { apolloClient, setAuthGetter } from "./apolloClient";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useEffect } from "react";

export function ApolloProvider({ children }) {
  const auth = useAuth();

  useEffect(() => {
    // This connects your auth system with Apollo
    setAuthGetter(() => auth);
  }, [auth]);

  return (
    <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
  );
}

import { forwardRef, useMemo, useState, useCallback, useEffect, useRef } from "react"
import { Tag } from "@phosphor-icons/react"
import { Input } from "@/common/components/ui/input"
import { Button } from "@/common/components/ui/button"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/common/components/ui/tooltip"

// Note: We're assuming urlSchema is imported from somewhere. If not, you'll need to implement it.
import { urlSchema } from "../schemas"

const URLInput = forwardRef(({ id, value, placeholder, onChange }, ref) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  const labelRef = useRef(null)
  const buttonRef = useRef(null)
  const inputRef = useRef(null)
  const hasError = useMemo(() => !urlSchema.safeParse(value).success, [value])

  const toggleLabel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsLabelOpen((prev) => !prev)
  }, [])

  const handleLabelChange = useCallback(
    (event) => {
      onChange({ ...value, label: event.target.value })
    },
    [onChange, value],
  )

  const handleClickOutside = useCallback(
    (e) => {
      if (
        isLabelOpen &&
        labelRef.current &&
        !labelRef.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setIsLabelOpen(false)
      }
    },
    [isLabelOpen],
  )

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (isLabelOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isLabelOpen])

  return (
    <>
      <div className="flex gap-x-1 relative">
        <Input
          ref={ref}
          id={id}
          value={value.href}
          className="flex-1"
          placeholder={placeholder}
          onChange={(event) => {
            onChange({ ...value, href: event.target.value })
          }}
        />

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                ref={buttonRef}
                size="icon"
                variant="ghost"
                onClick={toggleLabel}
                onMouseDown={(e) => e.preventDefault()} // Prevent focus loss
              >
                <Tag />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Label</TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {isLabelOpen && (
          <div
            ref={labelRef}
            className="absolute right-0 top-full mt-1 bg-white border rounded-md shadow-lg p-1.5 z-50"
          >
            <Input
              ref={inputRef}
              value={value.label}
              placeholder="Label"
              onChange={handleLabelChange}
              onMouseDown={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            />
          </div>
        )}
      </div>
      {hasError && <small className="text-destructive">URL must start with https://</small>}
    </>
  )
})

URLInput.displayName = "URLInput"

export default URLInput


import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@/features/auth/hooks/useAuth";
import Sidebar from "@/features/sidebar/components/Sidebar";
import { TimeTrackingService } from "@/features/time-sync/TimeTrackingService";

export function ProtectedRoute({ children, requireOnboarding = true }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="w-8 h-8 border-4 rounded-full animate-spin border-primary border-t-transparent"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  // Check if onboarding is required and not completed
  if (requireOnboarding && user?.profile?.onboarding_complete === false) {
    return <Navigate to="/welcome" replace />;
  }

  return (
    <Sidebar>
      {/* TimeTrackingService will only run for authenticated users */}
      <TimeTrackingService />
      <Outlet />
      {children}
    </Sidebar>
  );
}

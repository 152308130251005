import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "@/features/auth/hooks/useAuth";
import * as Sentry from "@sentry/react";

// Error handling link with Sentry integration
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // Standard console error (keep existing logging)
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        // Create structured data for Sentry
        const errorData = {
          operationName: operation.operationName,
          variables: operation.variables,
          query: operation.query?.loc?.source?.body,
          path: path,
          locations: locations,
        };

        // Report to Sentry with different severity based on error type
        let level = "error";

        // Handle specific error codes
        switch (extensions?.code) {
          case "UNAUTHENTICATED":
            // Handle authentication errors
            level = "warning";

            Sentry.captureMessage(`GraphQL Authentication Error: ${message}`, {
              level,
              tags: {
                graphql: true,
                errorCode: extensions?.code,
                operationType: operation.operationName ? "named" : "anonymous",
                operationName: operation.operationName || "unknown",
              },
              extra: errorData,
            });
            break;

          case "FORBIDDEN":
            // Handle authorization errors
            level = "warning";

            Sentry.captureMessage(`GraphQL Authorization Error: ${message}`, {
              level,
              tags: {
                graphql: true,
                errorCode: extensions?.code,
                operationType: operation.operationName ? "named" : "anonymous",
                operationName: operation.operationName || "unknown",
              },
              extra: errorData,
            });
            break;

          default:
            // Generic GraphQL error - capture as exception for better tracking
            const error = new Error(`GraphQL Error: ${message}`);
            error.name = "GraphQLError";

            Sentry.captureException(error, {
              tags: {
                graphql: true,
                errorCode: extensions?.code || "unknown",
                operationType: operation.operationName ? "named" : "anonymous",
                operationName: operation.operationName || "unknown",
              },
              extra: errorData,
            });
            break;
        }

        // Add breadcrumb to trace the error in the user journey
        Sentry.addBreadcrumb({
          category: "graphql",
          message: `GraphQL error: ${message}`,
          level: "error",
          data: {
            operationName: operation.operationName || "anonymous operation",
            path: path?.join("."),
            errorCode: extensions?.code,
          },
        });
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      // Report network errors to Sentry
      Sentry.captureException(networkError, {
        tags: {
          graphql: true,
          errorType: "network",
          operationType: operation.operationName ? "named" : "anonymous",
          operationName: operation.operationName || "unknown",
        },
        extra: {
          operationName: operation.operationName,
          variables: operation.variables,
          query: operation.query?.loc?.source?.body,
        },
      });

      // Add breadcrumb for network error
      Sentry.addBreadcrumb({
        category: "graphql",
        message: `Network error: ${
          networkError.message || "Unknown network error"
        }`,
        level: "error",
        data: {
          operationName: operation.operationName || "anonymous operation",
          statusCode: networkError.statusCode,
        },
      });
    }

    return forward(operation);
  }
);

// Create a function to get the current auth state
let getAuth = null;

// Simplify setAuthGetter to just pass through the auth context
export const setAuthGetter = (authGetter) => {
  getAuth = authGetter;
};

// Modified Sentry tracing link that works without startTransaction
const sentryTracingLink = new ApolloLink((operation, forward) => {
  // Get operation details
  const operationName =
    operation.operationName || "anonymous GraphQL operation";
  const operationType = operation.operationType || "query";

  // Add breadcrumb for operation tracking
  Sentry.addBreadcrumb({
    category: "graphql",
    message: `GraphQL operation: ${operationName}`,
    level: "info",
    data: {
      operationType: operationType,
      variables: operation.variables,
    },
  });

  // Start time measurement manually instead of using transactions
  const startTime = performance.now();

  // Forward the operation to the next link in the chain
  return forward(operation).map((response) => {
    // Calculate operation duration
    const endTime = performance.now();
    const duration = endTime - startTime;

    // Log performance data as a breadcrumb
    Sentry.addBreadcrumb({
      category: "performance",
      message: `GraphQL ${operationType} completed: ${operationName}`,
      level: response.errors?.length > 0 ? "warning" : "info",
      data: {
        operationName: operationName,
        operationType: operationType,
        durationMs: Math.round(duration),
        hasErrors: !!response.errors,
        errorCount: response.errors?.length || 0,
      },
    });

    // If there are errors and we have performance data, add a custom measurement
    if (response.errors && response.errors.length > 0) {
      // Create a structured error message with performance data
      Sentry.withScope((scope) => {
        scope.setTag("graphql_performance", true);
        scope.setTag("operation_name", operationName);
        scope.setTag("operation_type", operationType);
        scope.setExtra("durationMs", Math.round(duration));
        scope.setExtra("hasErrors", true);
        scope.setExtra("errorCount", response.errors.length);

        // Log it as a message rather than an exception
        Sentry.captureMessage(
          `Slow GraphQL operation with errors: ${operationName}`,
          "warning"
        );
      });
    }

    return response;
  });
});

// Auth middleware to attach token to headers
const authMiddleware = new ApolloLink((operation, forward) => {
  if (!getAuth) {
    return forward(operation);
  }

  const auth = getAuth();

  return new Promise((resolve) => {
    const fetchToken = async () => {
      try {
        const token = await auth.getLatestToken();

        if (token) {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }));
        } else {
          console.warn("No token available");
        }

        resolve(forward(operation));
      } catch (error) {
        console.error("Failed to get auth token:", error);
        Sentry.captureException(error, {
          tags: {
            component: "apolloClient",
            action: "fetchToken",
          },
        });
        resolve(forward(operation));
      }
    };

    fetchToken();
  });
});

// HTTP link with auth header
const httpLink = new HttpLink({
  uri: import.meta.env.VITE_HASURA_GRAPHQL_URL, // Your Hasura GraphQL endpoint
  // headers: {
  //     'x-hasura-admin-secret': import.meta.env.VITE_HASURA_ADMIN_SECRET,
  // }
});

// Cache configuration
const cache = new InMemoryCache({
  typePolicies: {
    // Add type policies here if needed
    Query: {
      fields: {
        // Add field policies here if needed
      },
    },
  },
});

// Apollo Client instance - updated link chain to include Sentry tracing
export const apolloClient = new ApolloClient({
  link: from([authMiddleware, sentryTracingLink, errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

import React from "react";

/**
 * Enhanced lazy loading with retry logic
 * @param {Function} importFunction - Dynamic import function
 * @param {number} retryCount - Number of retries (default: 3)
 */
export const lazyWithRetry = (importFunction, retryCount = 3) => {
  const retry = async (fn, retriesLeft = retryCount, interval = 1000) => {
    try {
      return await fn();
    } catch (error) {
      console.warn(
        `Dynamic import failed, retries left: ${retriesLeft}`,
        error
      );

      if (retriesLeft === 0) {
        throw error;
      }

      // Wait before retrying
      await new Promise((resolve) => setTimeout(resolve, interval));
      return retry(fn, retriesLeft - 1, interval * 1.5);
    }
  };

  return React.lazy(() => retry(importFunction));
};

import React, { useEffect } from "react";
import Home from "./pages/Home";
import "./assets/styles/global.css";
import { AuthProvider } from "@/features/auth/context/AuthContext";
import { AppRoutes } from "@/core/router/AppRoutes";
import { ApolloProvider } from "@/lib/apollo/ApolloProvider";
import { ToastContainer } from "react-toastify";
import { useUniversity } from "@/features/auth/hooks/useUniversity";
import FallbackLoader from "@/common/components/shared/FallbackLoader";
import { initGA } from "@/lib/analytics/ga4";

function App() {
  const { university, loading, error } = useUniversity();

  // Initialize Google Analytics with university data
  useEffect(() => {
    if (university) {
      initGA(university);
    } else {
      initGA();
    }
  }, [university]);

  if (loading) {
    return <FallbackLoader message="Initializing.." />;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center w-full min-h-[500px] px-4">
        <p className="text-red-500">
          Failed to load university configuration. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <AuthProvider university={university}>
      <ApolloProvider>
        <AppRoutes university={university} />
        <ToastContainer newestOnTop theme="colored" position="top-right" />
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;

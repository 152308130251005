import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

/**
 * @typedef {Object} UserProfile
 * @property {string} fullName
 * @property {string} id
 * @property {boolean} onboarding_complete
 * @property {Object} user_role
 * @property {string} user_role.id
 * @property {Object} user_role.role
 * @property {string} user_role.role.id
 * @property {string} user_role.role.name
 * @property {boolean} is_trainer
 * @property {string} onboarding_step
 * @property {string} profile_picture
 * @property {string} university_id
 * @property {Object} university
 * @property {string} university.id
 * @property {string} university.name
 * @property {string} university.description
 * @property {Object} university.config
 * @property {string} university.support_email
 * @property {string} university.icon
 * @property {Object} university.private_config
 * @property {boolean} university.isJobPortal
 */

/**
 * @typedef {Object} University
 * @property {string} tenantId - Firebase tenant ID
 * @property {string} name - University name
 * @property {string} [description] - University description
 * @property {Object} [config] - University configuration
 */

/**
 * @typedef {Object} User
 * @property {string} uid - User ID
 * @property {string} email - User email
 * @property {string} [displayName] - User display name
 * @property {string} [photoURL] - User photo URL
 * @property {string} token - User authentication token
 * @property {number} tokenExpiration - Token expiration timestamp
 * @property {Object} hasuraClaims - Hasura JWT claims
 * @property {University} university - University information
 * @property {UserProfile} [profile] - User profile data from GraphQL
 */

/**
 * @typedef {Object} AuthContextType
 * @property {User|null} user - Current user object
 * @property {boolean} loading - Loading state
 * @property {boolean} isRefreshing - Token refresh state
 * @property {University} university - University configuration
 * @property {(userData: Partial<User>) => void} setCurrentUser - Update current user data
 * @property {(email: string, password: string) => Promise<any>} login - Email/password login
 * @property {(email: string, password: string) => Promise<any>} signup - Email/password signup
 * @property {() => Promise<void>} logout - Logout function
 * @property {() => Promise<any>} googleLogin - Google login
 * @property {() => Promise<any>} githubLogin - GitHub login
 * @property {() => Promise<any>} microsoftLogin - Microsoft login
 * @property {(email: string) => Promise<void>} resetPassword - Password reset
 * @property {(force?: boolean) => Promise<string|null>} refreshToken - Token refresh
 */

/**
 * Custom hook to access authentication context
 * @returns {AuthContextType & {
 *   getLatestToken: () => Promise<string|null>,
 *   updateUserData: (updates: Partial<User>) => Promise<void>
 * }} Authentication context with additional token getter and user updater
 * @throws {Error} If used outside of AuthProvider
 */
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const getLatestToken = async () => {
    if (!context.user) return null;

    const now = Date.now();
    const tokenExpiration = context.user.tokenExpiration;
    const timeUntilExpiry = tokenExpiration - now;

    if (timeUntilExpiry < 5 * 60 * 1000) {
      console.log("Token is about to expire, refreshing token...");
      return await context.refreshToken(true);
    }

    console.log("Returning existing token...");
    return context.user.token;
  };

  const updateUserData = async (updates) => {
    if (!context.user) return;

    context.setCurrentUser({
      ...context.user,
      ...updates,
    });
  };

  return {
    ...context,
    getLatestToken,
    updateUserData,
  };
};

import React from "react";

const FallbackLoader = ({
  message = "Loading",
  subMessage = "Please wait while we prepare your content",
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full min-h-[500px] px-4">
      <div className="relative">
        {/* Outer ring */}
        <div className="w-16 h-16 border-4 border-gray-200 rounded-full dark:border-gray-700"></div>
        {/* Spinning inner ring */}
        <div className="absolute top-0 left-0 w-16 h-16 border-4 rounded-full border-t-primary border-r-transparent border-b-transparent border-l-transparent animate-spin"></div>
      </div>

      <div className="mt-6 text-lg font-medium text-gray-700 dark:text-gray-300">
        {message}
        <span className="animate-pulse">.</span>
        <span className="delay-150 animate-pulse">.</span>
        <span className="delay-300 animate-pulse">.</span>
      </div>

      <p className="max-w-xs mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
        {subMessage}
      </p>
    </div>
  );
};

export default FallbackLoader;
